// menu button

.menu-btn {
    position: absolute;
    z-index: 3;
    right: 35px;
    top: 35px;
    cursor: pointer;
    @include easeOut;
    

    .btn-line {
        width: 28px;
        height: 3px;
        margin: 0 0 5px 0;
        background: set-text-color($primary-color);
        @include easeOut;

        &:hover {
            background-color: $secondary-color;
            @include easeOut()
        }
        
    }
    

        // Rotate into x 
    &.close {
        transform: rotate(180deg);

        .btn-line {
           //1yes
           &:nth-child(1) {
                transform: rotate(45deg) translate(5px, 5px);
                &:hover {
                color: $secondary-color;
                @include easeOut()
            }
           }
           //2hide
           &:nth-child(2) {
                display: none;
           }
           //3yes
           &:nth-child(3) {
            transform: rotate(-45deg) translate(3px, -3px);  
           }
        }
    }
}

//Menu

.menu {
    position: fixed;
    top: 0;
    width: 100%;
    //opacity: .9;
    visibility: hidden;

    &.show {
        visibility: visible;   
    } 

    &-branding, 
    &-nav {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        float: left;
        width: 50%;
        height: 100vh;
        overflow: hidden;
    }

    &-nav {
        margin: 0;
        padding: 0;
        background: darken($primary-color, 5);
        list-style: none;
        transform: translate3d(0, -100%, 0);
        @include easeOut;

        &.show {
            transform: translate3d(0, 0, 0);
        }
    }

    //Branding
    &-branding {
        background-color: $primary-color;
        @include easeOut;
        transform: translate3d(0, 100%, 0);

        &.show {
            transform: translate3d(0, 0, 0);
        }

        .portrait {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            border: 4px solid $secondary-color;
        }

    }

    .nav-item {
        transform: translate3d(600px, 0, 0);
        @include easeOut;

        &.show {
            //from right
            transform: translate3d(0, 0, 0);
        }

        &.current > a {
            color: $secondary-color;
        }
    }

    .nav-link {
        display: inline-block;
        position: relative;
        font-size: 20px;
        text-transform: uppercase;
        padding: 1rem 0;
        font-weight: 300;
        color: set-text-color($primary-color);
        text-decoration: none;
        @include easeOut;

        &:hover {
            color: $secondary-color;
        }

    }


}


// delay by.1s

@for $x from 1 through 4 {
    .nav-item:nth-child(#{$x}) {
        transition-delay: $x * 0.1s;
    }
}
//
