$primary-color: rgb(23, 23, 23);
$font-color: rgb(4, 16, 251);
$secondary-color: #03d5fa;
$background-opacity: .8;

* {
    box-sizing: border-box;
}


body {
    background: $primary-color;
    opacity: $background-opacity;
    color: set-text-color($primary-color);
    height: 100%;
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.5;
}

@mixin easeOut {
    transition: all 0.5s ease-out;
}

@mixin background {
    @if $show-home-image {
        &#bg-img {
            background: $home-image;
            background-attachment: fixed;
            background-size: cover;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: rgba($primary-color, $background-opacity);
                z-index: -1;
            }
        }
    }
}
   
// light theme
@function set-text-color ($color) {
    @if (lightness($color) > 40) {
        @return rgb(0, 0, 0);  
    } @else  {
        @return #fff;
};
};




//media mixin
@mixin mediaSm {
    @media screen and (max-width: 500px) {
        @content;
    }
}

@mixin mediaMd {
    @media screen and (max-width: 850px) {
        @content;
    }
}

@mixin mediaLg {
    @media screen and (min-width: 851px) and (max-width: 1170px) {
        @content;
    }
}

@mixin mediaXl {
    @media screen and (min-width: 1171px) {
        @content;
    }
}
