@import './config.scss';
@import './navMenu.scss';
@import './mobile.scss';
@import './chat.scss';



// headings
h1, h2, h3, p {
    margin: 0;
    font-weight: 400;
    color: set-text-color($primary-color);

    &.lg-heading {
        font-size: 3.9rem;
        
    }

    &.sm-heading {
        margin-bottom: 1.7rem;
        padding: 0.2rem 1rem;
        background: rgba(lighten($primary-color, 2), 0.4);

    }
}

p, h6 {
    color: set-text-color($primary-color);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.5;
}

a {
    text-decoration: none;
    color: set-text-color($primary-color);
}

.home-img {
    position: absolute;
    width: 123px;
    height: 100vh;
    right: 45%;
    top: 0px;
}
.home-img2 {
    position: absolute;
    width: 123px;
    height: 100vh;
    right: 30%;
    top: 0px;
}
.home-img3 {
    position: absolute;
    width: 123px;
    height: 100vh;
    right: 0;
    top: 0px;
}
.home-img4 {
    position: absolute;
    width: 123px;
    height: 100vh;
    right: 15%;
    top: 0px;
}

header {
    position: fixed;
    z-index: 2;
    width: 100%;
}

.text-secondary {
    color: $secondary-color;
}

main {
    padding: 4rem;
    //height: 100%;
    min-height: calc(100vh - 60px);

    .icons {
        margin-top: 1rem;
        font-size: 45px;

        a {
            padding: 0.4rem;

            &:hover {
                color: $secondary-color;
                @include easeOut()
            }
        }
    }
    &#home {
        overflow: hidden;

        h1 {
            margin-top: 20vh;
        }
    }
}

.about-info {
    display: grid;
    grid-gap: 30px;
    grid-template-areas: 
    'bioimage bio bio'
    'job1 job2 job3';
    grid-template-columns: repeat(3, 1fr);

    img {
        width: 20vw;
    }
}

.bio-image {
    grid-area: bioimage;
    margin: auto;
    border-radius: 50%;
    border: $secondary-color 3px solid;

}

.bio {
    grid-area: bio;
    font-size: 1.5rem;
}

.job-1 {
    grid-area: job1;
    
    .lang-icons {
        height: 50%;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: set-text-color($primary-color);
        font-size: 40px;
    }
}

.job-2 {
    grid-area: job2;

    h3 {
        font-size: 30px;
        color: $secondary-color;
    }

    p {
        font-size: 20px;
    }
}

.job-3 {
    grid-area: job3;
    h3 {
        font-size: 30px;
        color: $secondary-color;
    }

    p{
        font-size: 18px;
    }
}

.job {
    background: lighten($primary-color, 5);
    padding: 0.5rem;
    border-bottom: $secondary-color 3px solid;


}


//portfolios

.projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;



    img {
        width: 100%;
        border: 3px solid $secondary-color;
        opacity: .9;
        border-radius: 15px;

        &:hover {
            opacity: 1;
            box-shadow: 0px 0px 20px 5px #fcfcfc;
            transition: all .3s ease-out;
        }
        
    }
}

//contact page



//buttons 

.btn {
    font-size: .8rem;
    display: inline-block;
    padding: 0.5rem 1rem;
    border: 0;
    margin-bottom: 0.3rem;

    &:hover {
        background: $secondary-color;
        color: set-text-color($primary-color);
        @include easeOut
    }

}

.btn-dark{
    @extend .btn;
    //background: darken($primary-color, 50);
    color: #fff;
}

.btn-light {
    @extend .btn;
    //background: lighten($primary-color, 50);
    color: #fff;

}

.contact {

        padding: auto 10%;

    .contact-list {
        opacity: .9;
        width: 80%;
        margin: 10%;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;

        .contact-item {
            font-size: 1.5rem;
            border-bottom: 3px solid #fff;
            padding: 10px;

            &:hover {
                background-color: lighten($primary-color, 10);
                border: 1.5px $secondary-color solid;
                @include easeOut
            }
        }
    }

}


#main-footer {
    text-align: center;
    padding: 1rem;
    background: darken($primary-color, 10);
    color: set-text-color($primary-color);
    height: 60px;

}


@import './mobile.scss';
