// Widescreen
@include mediaXl {
    .projects{
        grid-template-columns: repeat(2, 1fr);
    }
}

// desk and lap
@include mediaLg {
    
}

// small lad and tab
@include mediaMd {
    main {
        align-items: center;
        text-align: center;

        .lg-heading {
            line-height: 1;
            margin-bottom: 1rem;
        }
    }

    ul.menu-nav,
    div.menu-branding {
        float: none;
        width: 100%;
        min-height: 0;

        &.show {
            transform: translate3d(0, 0, 0);
        }

    }

    .menu-nav {
        height: 75vh;
        transform: translate3d(-100%, 0, 0);
        font-size: 24px;
    }

    .menu-branding {
        height: 25vh;
        transform: translate3d(100%, 0, 0);

        .portrait {
            width: 150px;
            height: 150px;
        }
    }

    .projects{
    grid-template-columns: repeat(2, 1fr);
}

.home-img, .home-img2, .home-img3, .home-img4   {
    opacity: .2;
    width: 123px;
}
}



// smartphones
@include mediaSm {
    main#home h1 {
        margin-top: 10vh;
        }

        .projects{
            grid-template-columns: 1fr;
        }

        .home-img, .home-img2, .home-img3, .home-img4   {
            opacity: .1;
            width: 123px;
        }

    .contact-list {
        opacity: .9;
        width: 80%;
        margin: 10%;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;

        .contact-item {
            font-size: 1.5rem;
            border-bottom: 3px solid #fff;
            padding: 10px;

            &:hover {
                background-color: lighten($primary-color, 10);
                border: 1.5px $secondary-color solid;
                @include easeOut
            }
        }
    }


    .contact {

        padding: auto;

    .contact-list {
        width: 100%;
        margin: 0;

        .contact-item {
            padding: 0;

        }
    }

}
}



@include mediaMd {
    .about-info {
        display: grid;
        grid-gap: 30px;
        grid-template-areas: 
        'bioimage' 
        'bio'
        'job1' 
        'job2' 
        'job3';
        grid-template-columns: 1fr;
    }
}
