.chat{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    box-sizing: border-box;
    position: fixed;
    width: 120vw;
    height: 100vh;
    bottom: 0px;
    background: $primary-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    padding-top: 50px;

    &:hover {
        box-shadow: -8px 10px 18px 0px;
        border: 2px solid $secondary-color;
    }

    h2 {
        
        text-align: center;
        height: 20px;
        font-style: normal;
        font-weight: 300;
        font-size: 2rem;
        line-height: 2rem;
    }

    form {
        height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        

        input, textarea {
            margin-top: 20px;
            font-size: 1rem;
            width: 200px;
            line-height: 1rem;
            border-top-style: hidden;
            border-right-style: hidden;
            border-left-style: hidden;
            border-bottom-style: groove;
            color: set-text-color($primary-color);
            background-color: $primary-color;

            &:hover {
                opacity: $background-opacity;
            }
        }

        button {
            margin-top: 10px;
            width: 200px;
            background: $primary-color;
            border: $secondary-color 3px solid;
            color: $secondary-color;
            font-size: 1.5rem;
            font-weight:300;
            border-radius: 7px;
            line-height: 2rem;
            &:hover {
                opacity: $background-opacity;
            }
        }
    }
    }

    .chat-not-live {
        right: 4000px;
        transition: 1.5s;
    }

    .chat-live {
        right: 0px;
        transition: 1.5s;
        z-index: 3;

    }

    .chat-icon {
        position: fixed;
        z-index: 3;
        top: 25px;
        right: 70px;
        width: 50px;
        font-size: 35px;
        color: set-text-color($primary-color);
        &:hover {
            color: $secondary-color;
            @include easeOut()
        }
    }
